import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
    <Layout className="container text-center">
        <SEO title="404: Not found" />
        <h1 className="mb-5">NOT FOUND</h1>
        <p>Page not found.</p>
    </Layout>
);

export default NotFoundPage;
